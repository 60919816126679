import React from "react";
import Layout from '../components/layout';
import * as textCss from './modules/text.module.scss';
import {graphql, useStaticQuery} from "gatsby";
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";

const RenseignementPage = () => {
    const datas = useStaticQuery(graphql`
        query {
          allContentfulRenseignements {
            edges {
              node {
                titre
                sousTitre
                imageMiseEnAvant {
                  file {url}
                  title
                }
                contenuDeLaPage {
                  raw
                }
              }
            }
          }
        }`
    );

    const data = datas.allContentfulRenseignements.edges[0].node;
    const content = JSON.parse(data.contenuDeLaPage.raw);

    return (
        <Layout>
            <div className={textCss.container}>
                <h1>{data.titre}</h1>
                <h2>{data.sousTitre}</h2>
                {data.imageMiseEnAvant.file.url && <img className={textCss.img} src={data.imageMiseEnAvant.file.url} alt={data.imageMiseEnAvant.title}/>}
                <div>{documentToReactComponents(content)}</div>

                <iframe title="gmap" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2677.1550791862874!2d3.9720540155378425!3d47.855959778712034!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47ee7aa901f516bd%3A0xe2706cf37a74f18c!2s33%20Rue%20de%20l&#39;H%C3%B4pital%2C%2089700%20Tonnerre!5e0!3m2!1sfr!2sfr!4v1621117526608!5m2!1sfr!2sfr" height="400" style={{border: 0}} allowfullscreen="" className={textCss.iframe} loading="lazy"></iframe>
            </div>
        </Layout>
    )
}

export default RenseignementPage;
